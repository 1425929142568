import React, { useEffect } from 'react'
import Prism from 'prismjs'
import csharp from 'prismjs/components/prism-csharp'

const Dotnet = () => {
  useEffect(() => Prism.highlightAll([csharp]), [])
  return (
    <>
      <div className="page--home m-8 mb-12">
        <h1 className="md:text-3xl text-2xl font-semibold mb-3">Couchbase and .NET</h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 p-0 lg:pt-5 mb-5">
          <div className="rounded overflow-hidden shadow-lg mx-0  my-5 lg:mr-6 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/dotnet-sdk/current/hello-world/start-using-sdk.html">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.couchbase.com/dotnet-sdk/current/project-docs/sdk-release-notes.html">
                  <span>Release Notes</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://issues.couchbase.com/projects/NCBC/issues">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/net-sdk/">
                  <span>Ask a Question</span>
                </a>

              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                The .NET SDK
              </div>
              <p className="text-gray-700 text-base font-light mb-6">
                The Couchbase .NET client allows applications to connect to Couchbase Server using any Common Language Runtime (CLR) language, including C#, F#, and VB.NET.
                The SDK 3.0 version is a complete rewrite of the 2.x API, providing a simpler surface area and adding support for future Couchbase Server features like
                <a className="blue-link" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/developer-preview/collections/collections-overview.html"> Collections and Scopes</a>.
              </p>
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#fff' }}
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://docs.couchbase.com/dotnet-sdk/current/hello-world/start-using-sdk.html">Get Started</a>
              </div>
            </div>
          </div>
          <div className="rounded overflow-hidden shadow-lg mx-0  my-5 lg:ml-6 bg-gray-100">
            <div className="px-6 py-2">
              <div className="flex lg:justify-center flex-wrap lg:flex-no-wrap lg:px-6 py-2">

                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/couchbaselabs/Linq2Couchbase#linq2couchbase">
                  <span>Docs</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/couchbaselabs/Linq2Couchbase/releases">
                  <span>Release Notes</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/couchbaselabs/Linq2Couchbase/issues">
                  <span>Report Issues</span>
                </a>
                <a className="flex items-center hover:no-underline hover:text-gray-800 mb-3 text-center md:mb-1 hover:bg-gray-500 bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forums.couchbase.com/c/net-sdk/">
                  <span>Ask a Question</span>
                </a>

              </div>
              <div className="font-bold text-xl mb-3 mt-3">
                Linq2Couchbase
              </div>
              <p className="text-gray-700 text-base font-light">
                The official Language Integrated Query
                <a className="blue-link" href="https://docs.couchbase.com/server/current/n1ql/n1ql-intro/queriesandresults.html"> (LINQ) </a>
                provider for querying Couchbase Server with N1QL using the Couchbase .NET SDK. The goal of Linq2Couchbase is to create a lightweight ORM/ODM for querying
                Couchbase Buckets using LINQ as the lingua-franca between your application and Couchbase Server using N1QL, a SQL-like query language for JSON documents.
              </p>
              <br />
              <div className="flex mt-4 mb-2 justify-center">
                <a target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#fff' }}
                  className="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded hover:no-underline"
                  href="https://github.com/couchbaselabs/Linq2Couchbase#getting-started">Get Started</a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-800 rounded pb-10 pt-5 px-5">
            <h2 className="text-white text-2xl md:ml-3 mb-3">Featured Content</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Couchbase GeoSearch
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      Couchbase GeoSearch with ASP.NET Core Ahmet Küçükoğlu shows how to use Couchbase's Full Text Search engine to create an ASP.NET Core API to find events based on location proximity.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://dev.to/ahmetkucukoglu/couchbase-geosearch-with-asp-net-core-i04">Read More</a>
                  </div>
                </div>
              </div>
              <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Microservices
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      ASP.NET Core Microservices Building a good microservice architecture is complex. These posts will help get you started with building a service and deploying it to Azure.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://blog.couchbase.com/asp-net-core-microservices-getting-started/">Read More</a>
                  </div>
                </div>
              </div>
              <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-3">
                <div className="flex flex-col min-h-full">
                  <div className="px-6 py-4 border-b">
                    <div className="text-xl text-center text-red-600">
                      Couchbase .NET SDK 3.0
                    </div>
                  </div>
                  <div className="px-6 py-5 flex-grow">
                    <p className="text-gray-700 text-base font-light">
                      Introducing the Couchbase .NET SDK 3.0 "Alpha" Releases The Couchbase .NET SDK 3.0 is no longer in Alpha, but this blog post contains
                      important background on the breaking changes between 2.x and 3.x.
                    </p>
                  </div>
                  <div className="px-5 py-3 flex justify-center">
                    <a target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#e53e3e' }}
                      className="btn-gradient-default hover:bg-red-100 border border-red-400 visited:text-red-600 text-red-600 font-medium text-sm py-3 px-5 rounded mr-3 mb-2 hover:no-underline"
                      href="https://blog.couchbase.com/introducing-the-couchbase-net-sdk-3-0-alpha-releases/">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:py-10 px-1 py-5">
          <h1 className="text-3xl font-semibold mb-3">Getting Started</h1>
          <p className="text-base">Connect to Couchbase from your .NET application</p>
          <p className="mt-6 mb-2 text-lg">Add the Couchbase .NET SDK to your project</p>

          <pre className="language-csharp pre codeblock line-numbers prism">
            <code className="bash">
              {`PM> Install-Package CouchbaseNetClient`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Insert data</p>

          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`await _bucket.InsertAsync(
        "key", new {foo = "bar"}
        );`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Retrieve data</p>

          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`var result =
    await _bucket.GetAsync("key");`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Query with SQL</p>

          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="sql">
              {`var result = await _bucket.QueryAsync(
    "SELECT b.* FROM mybucket"
    );`}
            </code>
          </pre>

          <p className="mt-6 mb-6 text-xl">Full quick start example:<br />
            <a className="blue-link" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/hello-world/start-using-sdk.html">Install and Start Using the .NET SDK with Couchbase Server</a></p>

          <h1 className="text-3xl font-semibold mb-3">Linq2Couchbase</h1>
          <p className="text-base">Write Linq (to generate N1QL) using <a className="blue-link" href="https://github.com/couchbaselabs/linq2couchbase">Linq2Couchbase</a>:</p>

          <p className="mt-6 mb-2 text-lg">Add Linq2Couchbase to your project</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="bash">
              {`PM> Install-Package Linq2Couchbase`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Create a BucketContext object</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`var context = new BucketContext(bucket);`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Start writing Linq</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`var beers = from b in
    _context.Query&lt;Beer&gt;()
    where b.Name == "Weizen"
    select b;`}
            </code>
          </pre>

          <p className="mt-6 mb-6 text-xl">Full quick start example:<br />
            <a className="blue-link"
              rel="noopener noreferrer"
              href="https://docs.couchbase.com/tutorials/quick-start/quickstart-dotnet27-aspnetcore31-visualstudio-firstquery-cb65.html">.NET Core with Linq2Couchbase First Query</a></p>

          <h1 className="text-3xl font-semibold mb-3">ASP.NET Core</h1>

          <p className="text-base">Integrate Couchbase with your ASP.NET Core project using <code className="code-hightlight">Couchbase.Extensions.DependencyInjection</code></p>

          <p className="mt-6 mb-2 text-lg">Add the library to your project</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="bash">
              {`PM> Install-Package Couchbase.Extensions.DependencyInjection`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">In ConfigureServices, add Couchbase to the services collection (in Startup.cs)</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`services.AddCouchbase(
        Configuration.GetSection("Couchbase")
        );`}
            </code>
          </pre>

          <p className="mt-6 mb-2 text-lg">Inject an IBucketProvider wherever you need it:</p>
          <pre className="language-csharp pre codeblock line-numbers prism">
            <code data-language="csharp">
              {`public MyController(IBucketProvider bucketProvider)
{
    _bucket = bucketProvider.GetBucket("travel-sample");
}`}
            </code>
          </pre>

          <p className="mt-6 text-xl">Full quick start example:<br />
            <a className="blue-link"
              rel="noopener noreferrer"
              href="https://docs.couchbase.com/tutorials/quick-start/quickstart-dotnet27-aspnetcore31-visualstudio-firstquery-cb65.html">ASP.NET Core First Query</a></p>
        </div>

        <div className="bg-gray-800 rounded  lg:pb-10 lg:pt-5 lg:px-5 p-4">
          <h2 className="text-white text-2xl mb-3 ">Get Engaged!</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
            <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-xl text-center text-red-600">
                    Stack Overflow
                  </div>
                </div>
                <div className="px-6 py-5 flex-grow">
                  <p className="text-gray-700 text-base font-light mb-4">
                    Ask your question on <a className="blue-link" href="https://stackoverflow.com/questions/ask">Stack Overflow</a> (use the "<code className="code-hightlight">couchbase</code>" tag)
                  </p>
                  <p className="text-gray-700 text-base font-light">Use your expertise to browse and answer other
                    <a className="blue-link" rel="noopener noreferrer" href="https://stackoverflow.com/questions/tagged/couchbase">Couchbase questions</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-xl text-center text-red-600">
                    Tweet!
                  </div>
                </div>
                <div className="px-6 py-5 flex-grow">
                  <p className="text-gray-700 text-base font-light mb-4">
                    Tweet your questions or comments on Twitter.
                  </p>
                  <p className="text-gray-700 text-base font-light">
                    Use hashtag <code className="code-hightlight">#couchbase</code> to make sure we see you!
                  </p>
                </div>
              </div>
            </div>
            <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-5">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-xl text-center text-red-600">
                    Gitter
                  </div>
                </div>
                <div className="px-6 py-5 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    Chat with SDK engineers and other SDK users on <a className="blue-link" href="https://gitter.im/couchbase/couchbase-dotnet-sdk">Gitter</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="max-w-full rounded-lg overflow-hidden shadow-lg bg-white md:m-3 mb-3">
              <div className="flex flex-col min-h-full">
                <div className="px-6 py-4 border-b">
                  <div className="text-xl text-center text-red-600">
                    .NET Forums
                  </div>
                </div>
                <div className="px-6 py-5 flex-grow">
                  <p className="text-gray-700 text-base font-light">
                    Ask your question on the <a rel="noopener noreferrer" className="blue-link" href="https://forums.couchbase.com/c/net-sdk">Couchbase .NET SDK forum</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dotnet